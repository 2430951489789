import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// @material-ui/lab components
// @material-ui/icons components

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import componentStyles from "assets/theme/views/admin/export.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import CardTimeline from "components/Cards/Cards/CardTimeline";
import CardFeedbackForm from "../Forms/CardFeedbackForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

const FeedbackPage = () => {
  const classes = { ...useStyles(), ...useStylesButtons(), ...useStylesCardDeck() };
  const { t, i18n } = useTranslation("translation", { keyPrefix: "Contact" });

  return (
    <>
      <AlternativeHeader section={t("header")} subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>Give Feedback | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
        marginTop="-4.5rem"
        style={{ position: "relative", zIndex: "1000" }}
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            {/* <CardFeedbackForm/> */}
            <div style={{ marginBottom: "1.5rem" }}>
              For any questions or suggestions, please contact:
              <div className="">
                <b>info@bullsheet.me</b>{" "}
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-clipboard"
                style={{ cursor: "pointer" }}
                onClick={() => navigator.clipboard.writeText("info@bullsheet.me")}
              >
                <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
              </svg> */}
              </div>
              <div>
                <i>We usually respond withing 24 hours.</i>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <CardTimeline />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FeedbackPage;
